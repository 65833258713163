import "@fontsource/nunito";
import "@fontsource/noto-sans-jp";
import "@aws-amplify/ui-react/styles.css";
import "src/styles/auth.css";
import "src/libs/translate";

import { Authenticator } from "@aws-amplify/ui-react";
import { Center, ChakraProvider } from "@chakra-ui/react";
import { Amplify } from "aws-amplify";
import type { AppProps } from "next/app";
import { RecoilRoot } from "recoil";
import awsconfig from "src/aws-exports";
import { AccessControl } from "src/components/functional/AccessControl";
import { AuthInit } from "src/components/functional/AuthInit";
import { SentryInit } from "src/components/functional/SentryInit";
import { Logo } from "src/components/ui/text/Logo";
import { theme } from "src/utils/theme";

Amplify.configure(awsconfig);

const components = {
  Header() {
    return (
      <Center>
        <Logo />
      </Center>
    );
  },
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <ChakraProvider theme={theme}>
      <Authenticator
        loginMechanisms={["email"]}
        signUpAttributes={["name"]}
        className="amplify-container"
        components={components}
        hideSignUp
      >
        {() => (
          <RecoilRoot>
            <AuthInit />
            <SentryInit />
            <AccessControl />
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Component {...pageProps} />
          </RecoilRoot>
        )}
      </Authenticator>
    </ChakraProvider>
  );
};

export default MyApp;
