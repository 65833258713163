import { extendTheme } from "@chakra-ui/react";

export const colors = {
  base: "#F4F4F4",
  black: "#333333",
  border: {
    light: "#EDF2F6",
    main: "#C4C4C4",
  },
  primary: {
    light: "#A5C5D6",
    main: "#004EA2",
    dark: "#082F50",
  },
  secondary: {
    main: "#949495",
  },
  error: "#F77777",
  warn: "#FFC303",
  success: "#19BC9B",
  link: "#3FA2F7",
  /**
   * NOTE: colorSchemeでメインカラー(colors.primary)を使いたかったので拡張(500番をprimary.mainに合わせている)
   * @see https://colors.eva.design/ のサイトで自動生成
   * */
  blue: {
    100: "#C8EAFA",
    200: "#93D0F5",
    300: "#5AA9E3",
    400: "#3180C7",
    500: "#004EA2",
    600: "#003C8B",
    700: "#002C74",
    800: "#001F5D",
    900: "#00164D",
  },
} as const;

const fonts = {
  heading: "Nunito",
  body: "Noto Sans JP",
} as const;

export const theme = extendTheme({
  colors,
  fonts,
});
