import { Heading } from "@chakra-ui/react";
import Link from "next/link";
import type { VFC } from "react";
import { colors } from "src/utils/theme";

export const Logo: VFC = () => {
  return (
    <Link href="/orders">
      <a>
        <Heading as="h1" fontWeight="bold">
          <span style={{ color: colors.primary.main }}>O</span>
          <span style={{ color: colors.secondary.main, marginRight: "16px" }}>gawa</span>
          <span style={{ color: colors.primary.main }}>F</span>
          <span style={{ color: colors.secondary.main }}>inder</span>
        </Heading>
      </a>
    </Link>
  );
};
