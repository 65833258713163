import type { Dict } from "mixpanel-browser";
import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ?? "");
const isTrack = process.env.NEXT_PUBLIC_MIXPANEL_TRACKING === "true";

type EventName =
  | "LOGIN"
  | "SEARCH_ORDERS_CONDITION"
  | "SEARCH_ORDER"
  | "ORDER_DETAIL_VIEW"
  | "ORDER_DETAIL_PRODUCT_LIST"
  | "ORDER_DETAIL_SHARE"
  | "CLICK_ORDER"
  | "test event";

export class Mixpanel {
  public static identify(uniqueId: string): void {
    isTrack && mixpanel.identify(uniqueId);
  }

  public static track(eventName: EventName, properties?: Dict): void {
    isTrack && mixpanel.track(eventName, properties);
  }

  public static setPeople(prop: Dict): void {
    isTrack && mixpanel.people.set(prop);
  }
}
