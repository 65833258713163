import { Auth } from "aws-amplify";
import { useCallback } from "react";

type CurrentUserInfo = {
  username: string;
  attributes: {
    sub: string;
    email_verified: boolean;
    name: string;
    email: string;
  };
};

export const useAuth = () => {
  const logout = useCallback(async () => {
    localStorage.removeItem("recoil-persist");
    await Auth.signOut();
  }, []);

  const getCognitoUserName = useCallback(async () => {
    const currentUserInfo: CurrentUserInfo = await Auth.currentUserInfo();
    return currentUserInfo.username;
  }, []);

  return { logout, getCognitoUserName };
};
