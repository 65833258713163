import { Amplify, Auth } from "aws-amplify";
import type { AWSAppSyncClientOptions } from "aws-appsync";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import awsExports from "src/aws-exports";

Amplify.configure(awsExports);

type AuthType = "cognito" | "lambda";
export const getAppSyncClient = (type: AuthType = "cognito") => {
  const option: AWSAppSyncClientOptions = {
    url: awsExports.aws_appsync_graphqlEndpoint,
    region: awsExports.aws_appsync_region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
    },
    disableOffline: true,
  };

  switch (type) {
    case "cognito": {
      return new AWSAppSyncClient({
        ...option,
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
        },
      });
    }
    case "lambda": {
      return new AWSAppSyncClient({
        ...option,
        auth: {
          type: AUTH_TYPE.AWS_LAMBDA,
          token: async () => (await Auth.currentUserInfo()).username,
        },
      });
    }
  }
};
