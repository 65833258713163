import * as Sentry from "@sentry/nextjs";
import gql from "graphql-tag";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import { recoilKeys } from "src/constants/recoilKeys";
import type { GetUserQuery, GetUserQueryVariables } from "src/graphql/API";
import { getUser } from "src/graphql/queries";
import { useAuth } from "src/hooks/useAuth";
import { getAppSyncClient } from "src/libs/appSyncClient";
import { Mixpanel } from "src/libs/mixpanel";
import type { GraphQLErrors, Response } from "src/types";

const { persistAtom } = recoilPersist();

const userState = atom<GetUserQuery["getUser"] | null>({
  key: recoilKeys["User"],
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const useUserStore = () => {
  const [user, setUser] = useRecoilState(userState);
  const { getCognitoUserName } = useAuth();
  const router = useRouter();

  const initUser = useCallback(async (): Promise<Response<GetUserQuery["getUser"]>> => {
    const cognitoUserName = await getCognitoUserName();

    const variables: GetUserQueryVariables = { id: cognitoUserName };
    const client = getAppSyncClient();
    const { data, errors } = await client
      .query<GetUserQuery, GetUserQueryVariables>({
        query: gql(getUser),
        fetchPolicy: "network-only",
        variables,
      })
      .catch((errors: GraphQLErrors) => {
        return { data: null, errors };
      });

    if (errors) {
      Sentry.captureException(errors, {
        extra: {
          query: getUser,
          variables,
        },
      });
      return { data: null, errors };
    }
    if (data === null) {
      return { data, errors };
    }

    const user = data.getUser;
    setUser(user);

    if (user) {
      Mixpanel.identify(user.id);
      if (user.customer) {
        Mixpanel.setPeople({
          $name: user.name,
          "User Type": "Customer",
          "Customer Code": user.customer.code,
          "Customer Name": user.customer.name,
        });
      } else if (user.salesOffice) {
        Mixpanel.setPeople({
          $name: user.name,
          "User Type": "Sales Office",
          "Sales Office Code": user.salesOffice.code,
          "Sales Office Name": user.salesOffice.name,
        });
      }
      Mixpanel.track("LOGIN");
    }

    if (data.getUser === null) {
      if (router.asPath !== "/forbidden") {
        router.push("/forbidden");
      }
    }

    return { data: user, errors };
  }, [getCognitoUserName, router, setUser]);

  return { user, initUser };
};
