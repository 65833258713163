import type { VFC } from "react";
import { useEffect } from "react";
import { useUserStore } from "src/store/useUserStore";

export const AuthInit: VFC = () => {
  const { user, initUser } = useUserStore();
  useEffect(() => {
    user || initUser();
  }, [user, initUser]);

  useEffect(() => {
    window.addEventListener("load", initUser);
    return () => {
      window.removeEventListener("load", initUser);
    };
  }, []);

  return null;
};
