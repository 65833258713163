import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
import { useAuth } from "src/hooks/useAuth";

export const SentryInit = () => {
  const { getCognitoUserName } = useAuth();

  useEffect(() => {
    const init = async () => {
      const username = await getCognitoUserName();
      Sentry.setUser({ cognito_user_name: username });
    };
    init();
  }, []);
  return null;
};
