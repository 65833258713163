import { translations } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";

const vocabularies = {
  ...translations["ja"],
  "Signing in": "ログイン中",
  "Invalid verification code provided, please try again.": "認証コードが一致しません",
  "Your passwords must match": "パスワードが一致しません",
  "Incorrect username or password.": "メールアドレスまたはパスワードに間違いがあります",
  ["Name"]: "ユーザー名",
  "User does not exist.": "存在しないアカウントです",
  "Username/client id combination not found.": "未登録のメールアドレスです",
  "Attempt limit exceeded, please try after some time.": "試行回数の制限を超えました。しばらくしてから試してください。",
  "Password must have at least 8 characters": "パスワードは8文字以上です。",
  "Invalid session for the user, session is expired.": "セッションの有効期限が切れています。",
  "Temporary password has expired and must be reset by an administrator.":
    "一時的なパスワードの有効期限が切れているため、管理者によるリセットが必要です。",
  "Code *": "コード",
} as const;

I18n.putVocabulariesForLanguage("ja", vocabularies);
I18n.setLanguage("ja");
