import { useRouter } from "next/router";
import type { FC } from "react";
import { useEffect } from "react";
import { useUserStore } from "src/store/useUserStore";

export const AccessControl: FC = () => {
  const { user } = useUserStore();
  const router = useRouter();

  useEffect(() => {
    if (router.pathname.includes("admin") && !user?.isAdmin) {
      router.push("/forbidden");
    }
  }, [router, user]);

  return null;
};
