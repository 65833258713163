/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEvaluateFeature = /* GraphQL */ `
  query GetEvaluateFeature($feature: String!) {
    getEvaluateFeature(feature: $feature) {
      details
      reason
      value {
        boolValue
        doubleValue
        longValue
        stringValue
        __typename
      }
      variation
      __typename
    }
  }
`;
export const getOrderInfo = /* GraphQL */ `
  query GetOrderInfo($orderNo: ID!) {
    getOrderInfo(orderNo: $orderNo) {
      orderNo
      products {
        items {
          productId
          orderNo
          order {
            orderNo
            slipSeq
            customerCode
            customerName
            remark1
            remark2
            orderStatus
            orderedAt
            orderedAtSlipSeq
            statusOrderedAtSlipSeq
            keywordProductName
            updatedAt
            __typename
          }
          customerCode
          customerName
          productCode
          productName
          quantity
          listPrice
          unitPrice
          amount
          orderedAt
          arrangedAt
          deliveryDate
          slipDate
          productStatus
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      slipSeq
      customerCode
      customerName
      remark1
      remark2
      orderStatus
      orderedAt
      orderedAtSlipSeq
      statusOrderedAtSlipSeq
      keywordProductName
      updatedAt
      __typename
    }
  }
`;
export const listOrderInfos = /* GraphQL */ `
  query ListOrderInfos(
    $orderNo: ID
    $filter: ModelOrderInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderInfos(
      orderNo: $orderNo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        orderNo
        products {
          items {
            productId
            orderNo
            customerCode
            customerName
            productCode
            productName
            quantity
            listPrice
            unitPrice
            amount
            orderedAt
            arrangedAt
            deliveryDate
            slipDate
            productStatus
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        slipSeq
        customerCode
        customerName
        remark1
        remark2
        orderStatus
        orderedAt
        orderedAtSlipSeq
        statusOrderedAtSlipSeq
        keywordProductName
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listOrderInfoBySpecificCustomer = /* GraphQL */ `
  query ListOrderInfoBySpecificCustomer(
    $customerCode: String!
    $orderedAtSlipSeq: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderInfoBySpecificCustomer(
      customerCode: $customerCode
      orderedAtSlipSeq: $orderedAtSlipSeq
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        orderNo
        products {
          items {
            productId
            orderNo
            customerCode
            customerName
            productCode
            productName
            quantity
            listPrice
            unitPrice
            amount
            orderedAt
            arrangedAt
            deliveryDate
            slipDate
            productStatus
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        slipSeq
        customerCode
        customerName
        remark1
        remark2
        orderStatus
        orderedAt
        orderedAtSlipSeq
        statusOrderedAtSlipSeq
        keywordProductName
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listOrderInfoBySpecificCustomerWithStatus = /* GraphQL */ `
  query ListOrderInfoBySpecificCustomerWithStatus(
    $customerCode: String!
    $statusOrderedAtSlipSeq: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderInfoBySpecificCustomerWithStatus(
      customerCode: $customerCode
      statusOrderedAtSlipSeq: $statusOrderedAtSlipSeq
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        orderNo
        products {
          items {
            productId
            orderNo
            customerCode
            customerName
            productCode
            productName
            quantity
            listPrice
            unitPrice
            amount
            orderedAt
            arrangedAt
            deliveryDate
            slipDate
            productStatus
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        slipSeq
        customerCode
        customerName
        remark1
        remark2
        orderStatus
        orderedAt
        orderedAtSlipSeq
        statusOrderedAtSlipSeq
        keywordProductName
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductInfo = /* GraphQL */ `
  query GetProductInfo($productId: ID!) {
    getProductInfo(productId: $productId) {
      productId
      orderNo
      order {
        orderNo
        products {
          items {
            productId
            orderNo
            customerCode
            customerName
            productCode
            productName
            quantity
            listPrice
            unitPrice
            amount
            orderedAt
            arrangedAt
            deliveryDate
            slipDate
            productStatus
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        slipSeq
        customerCode
        customerName
        remark1
        remark2
        orderStatus
        orderedAt
        orderedAtSlipSeq
        statusOrderedAtSlipSeq
        keywordProductName
        updatedAt
        __typename
      }
      customerCode
      customerName
      productCode
      productName
      quantity
      listPrice
      unitPrice
      amount
      orderedAt
      arrangedAt
      deliveryDate
      slipDate
      productStatus
      updatedAt
      __typename
    }
  }
`;
export const listProductInfos = /* GraphQL */ `
  query ListProductInfos(
    $productId: ID
    $filter: ModelProductInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductInfos(
      productId: $productId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        productId
        orderNo
        order {
          orderNo
          products {
            nextToken
            __typename
          }
          slipSeq
          customerCode
          customerName
          remark1
          remark2
          orderStatus
          orderedAt
          orderedAtSlipSeq
          statusOrderedAtSlipSeq
          keywordProductName
          updatedAt
          __typename
        }
        customerCode
        customerName
        productCode
        productName
        quantity
        listPrice
        unitPrice
        amount
        orderedAt
        arrangedAt
        deliveryDate
        slipDate
        productStatus
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listProductInfoByOrder = /* GraphQL */ `
  query ListProductInfoByOrder(
    $orderNo: ID!
    $productId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductInfoByOrder(
      orderNo: $orderNo
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        productId
        orderNo
        order {
          orderNo
          products {
            nextToken
            __typename
          }
          slipSeq
          customerCode
          customerName
          remark1
          remark2
          orderStatus
          orderedAt
          orderedAtSlipSeq
          statusOrderedAtSlipSeq
          keywordProductName
          updatedAt
          __typename
        }
        customerCode
        customerName
        productCode
        productName
        quantity
        listPrice
        unitPrice
        amount
        orderedAt
        arrangedAt
        deliveryDate
        slipDate
        productStatus
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      displayName
      role
      customerCode
      customer {
        code
        name
        salesOfficeCode
        salesOffice {
          code
          name
          customers {
            nextToken
            __typename
          }
          employee {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        employee {
          items {
            id
            name
            displayName
            role
            customerCode
            salesOfficeCode
            isAdmin
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      salesOfficeCode
      salesOffice {
        code
        name
        customers {
          items {
            code
            name
            salesOfficeCode
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        employee {
          items {
            id
            name
            displayName
            role
            customerCode
            salesOfficeCode
            isAdmin
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      isAdmin
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        displayName
        role
        customerCode
        customer {
          code
          name
          salesOfficeCode
          salesOffice {
            code
            name
            createdAt
            updatedAt
            __typename
          }
          employee {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        salesOfficeCode
        salesOffice {
          code
          name
          customers {
            nextToken
            __typename
          }
          employee {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUsersByCustomer = /* GraphQL */ `
  query ListUsersByCustomer(
    $customerCode: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByCustomer(
      customerCode: $customerCode
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        displayName
        role
        customerCode
        customer {
          code
          name
          salesOfficeCode
          salesOffice {
            code
            name
            createdAt
            updatedAt
            __typename
          }
          employee {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        salesOfficeCode
        salesOffice {
          code
          name
          customers {
            nextToken
            __typename
          }
          employee {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUsersBySalesOffice = /* GraphQL */ `
  query ListUsersBySalesOffice(
    $salesOfficeCode: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersBySalesOffice(
      salesOfficeCode: $salesOfficeCode
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        displayName
        role
        customerCode
        customer {
          code
          name
          salesOfficeCode
          salesOffice {
            code
            name
            createdAt
            updatedAt
            __typename
          }
          employee {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        salesOfficeCode
        salesOffice {
          code
          name
          customers {
            nextToken
            __typename
          }
          employee {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalesOffice = /* GraphQL */ `
  query GetSalesOffice($code: ID!) {
    getSalesOffice(code: $code) {
      code
      name
      customers {
        items {
          code
          name
          salesOfficeCode
          salesOffice {
            code
            name
            createdAt
            updatedAt
            __typename
          }
          employee {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      employee {
        items {
          id
          name
          displayName
          role
          customerCode
          customer {
            code
            name
            salesOfficeCode
            createdAt
            updatedAt
            __typename
          }
          salesOfficeCode
          salesOffice {
            code
            name
            createdAt
            updatedAt
            __typename
          }
          isAdmin
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSalesOffices = /* GraphQL */ `
  query ListSalesOffices(
    $code: ID
    $filter: ModelSalesOfficeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSalesOffices(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        name
        customers {
          items {
            code
            name
            salesOfficeCode
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        employee {
          items {
            id
            name
            displayName
            role
            customerCode
            salesOfficeCode
            isAdmin
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($code: ID!) {
    getCustomer(code: $code) {
      code
      name
      salesOfficeCode
      salesOffice {
        code
        name
        customers {
          items {
            code
            name
            salesOfficeCode
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        employee {
          items {
            id
            name
            displayName
            role
            customerCode
            salesOfficeCode
            isAdmin
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      employee {
        items {
          id
          name
          displayName
          role
          customerCode
          customer {
            code
            name
            salesOfficeCode
            createdAt
            updatedAt
            __typename
          }
          salesOfficeCode
          salesOffice {
            code
            name
            createdAt
            updatedAt
            __typename
          }
          isAdmin
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $code: ID
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomers(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        name
        salesOfficeCode
        salesOffice {
          code
          name
          customers {
            nextToken
            __typename
          }
          employee {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        employee {
          items {
            id
            name
            displayName
            role
            customerCode
            salesOfficeCode
            isAdmin
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCustomerBySalesOffice = /* GraphQL */ `
  query ListCustomerBySalesOffice(
    $salesOfficeCode: ID!
    $code: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerBySalesOffice(
      salesOfficeCode: $salesOfficeCode
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        code
        name
        salesOfficeCode
        salesOffice {
          code
          name
          customers {
            nextToken
            __typename
          }
          employee {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        employee {
          items {
            id
            name
            displayName
            role
            customerCode
            salesOfficeCode
            isAdmin
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
